import React, { useEffect, useRef, useState } from "react";
import { AiFillCloseCircle, AiFillCheckCircle } from "react-icons/ai";
import { BiDownload } from "react-icons/bi";
// import * as XLSX from "xlsx";
import Papa from "papaparse";
import Datasheet from "../../tmp/dataSheet.csv";
import DatePicker from "react-datepicker";
import * as xlsx from "xlsx";
import {
  // FaGreaterThan,
  FaEdit,
  // FaLessThan,
  FaTrashAlt,
  // FaUserAlt,
  // FaSearch,
} from "react-icons/fa";
import userRegistrationService from "../../services/userRegistration.service";
import { BsEye, BsEyeSlash } from "react-icons/bs";
import { AiOutlineClose } from "react-icons/ai";
import licenceValidator from "../../permission/validate";
import masterDBService from "../../services/masterDB.service";
function MultiUser() {
  const [showPopup, setShowPopup] = useState(false);
  const [showEditPopup, setShowEditPopup] = useState(false);
  const [shiftList, setShifts] = useState(["9:00 AM - 6:00 PM"]);
  const region = ["IND", "APAC", "CRP", "EU", "US"];
 

  const [showPassword, setShowPassword] = useState(false);
  // eslint-disable-next-line
  const [password, setPassword] = useState("");
  const [selectedUser, setSelectedUser] = useState();
  // const [successNotific, setSuccess] = useState("");
  // const [errorNotific, setError] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const limRef = useRef();
  let emailRef = useRef();
  let firstNameRef = useRef();
  let empIdRef = useRef();
  let lastNameRef = useRef();
  const departmentRef = useRef();
  const shiftRef = useRef();
  let usergroupRef = useRef();
  const shiftTimingsRef = useRef();
  const dojRef = useRef();
  const dobRef = useRef();
  let [users, setUsers] = useState([]);
  const [isVisible, setIsVisible] = useState(false);
  const [showCounter, setShowCounter] = useState(false);
  const [userUploadCounter, setUserUploadCounter] = useState(0);
  const [showUploadCounter, setShowUploadCounter] = useState(false);
  const [uploadedSuccessCounter, setUploadedSuccessCounter] = useState(0);
  const [uploadedFailedCounter, setUploadedFailedCounter] = useState(0);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");
  const [dateOfBirth,setDateOfBirth]= useState("");
  const [dateOfJoining,setDateOfJoining] = useState("");
  const [departments,setDepartments] = useState([])
  const [selectedDepartment, setSelectedDepartment] = useState('');
  const [selectedShift,setSelectedShift] = useState('');
  const [shiftTimings, setShiftTimings] = useState([]);
  const [shiftTime,setShiftTime] = useState('');

  useEffect(()=>{
  getMasterDB()
  },[])

  const getMasterDB = async()=>{
    try {
      const response = await masterDBService.getMasterDB();
      setDepartments(response.results)
    } catch (error) {
      console.error(error)
    }
  }

  const handleDepartmentChange = () => {
    const selectedDepartmentName = departmentRef.current.value;
    setSelectedDepartment(selectedDepartmentName);
    shiftTimingsRef.current.value="default";
    shiftRef.current.value="default";
    setSelectedShift(''); 
  };

  const handleShiftChange = async () => {
    const selectedShiftName = shiftRef.current.value;
    setSelectedShift(selectedShiftName);
    const selectedDepartmentData = departments.find(department => department.name === selectedDepartment);
    const selectedShiftData = selectedDepartmentData?.shifts.find(shift => shift.name === selectedShiftName);
    setShiftTimings(selectedShiftData?.shiftTimings || []);
    setShiftTime('')
    shiftTimingsRef.current.value="default";
  };

  const handleShiftTimingChange = async () =>{
    const selectedShiftTime = shiftTimingsRef.current.value;
    setShiftTime(selectedShiftTime);
  }

  const handleUserRegistration = async () => {
    setUserUploadCounter(0)
    setShowCounter(true);
    setShowUploadCounter(true);
    const response = await userRegistrationService.getUsers();
    const data = response.results;
    console.log("Data fetched successfully:", data);

    for (const user of users) {
      let dojParts = user.dateOfJoining.split("/");
      let dobParts = user.dateOfBirth.split("/");
      if(dojParts.length < 3){
        dojParts = user.dateOfJoining.split("-")

      }
      if(dobParts.length < 3){
        dobParts = user.dateOfBirth.split("-")
        
      }
      let errors = []
   
      try {
        
        if(!/^[a-zA-Z\s]*$/g.test(user.firstName) || !user.firstName){
          
          errors.push("first name")
          
        }
      
        if(!/^[a-zA-Z\s]*$/g.test(user.lastName) || !user.lastName){
          errors.push("last name")
          
        }
        if(!/^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/.test(user.email) || !user.email){
          errors.push("email")
          
        }
        if (!departments.find(department => department.name === user.department)) {
          errors.push("department");
        }
      
        const selectedDepartmentData = departments.find(department => department.name === user.department);
        
        if (selectedDepartmentData) {
            const selectedShiftData = selectedDepartmentData.shifts.find(shift => shift.name === user.shift);
            
            if (!selectedShiftData) {
                errors.push("shift");
            } else {
              
                if (!selectedShiftData.shiftTimings.includes(user.shiftTiming)) {
                  
                    errors.push("shiftTiming");
                }
            }
        }

        const dobRegex = /^\d{2}-\d{2}-\d{4}$/;

        if (!dobRegex.test(user.dateOfBirth)) {
          
            errors.push("dateOfBirth");
        } else {
            const dobDate = new Date(`${dobParts[0]}-${dobParts[1]}-${dobParts[2]}`);
            user.dateOfBirth = `${dobParts[2]}-${dobParts[1]}-${dobParts[1]}`
            const today = new Date();
            if (dobDate >= today) {
              
                errors.push("dateOfBirth");
            }
        }

        const dojRegex = /^\d{2}-\d{2}-\d{4}$/;

        if (!dojRegex.test(user.dateOfJoining)) {
          
            errors.push("dateOfJoining");
        } else {
            const dojDate = new Date(`${dojParts[2]}-${dojParts[1]}-${dojParts[0]}`);
            user.dateOfJoining = `${dojParts[2]}-${dojParts[1]}-${dojParts[0]}`
            const today = new Date();
            if (dojDate >= today) {
              
                errors.push("dateOfJoining");
            }
        }

        if (!/^(?=.*[A-Za-z])(?=.*\d).{8,}$/.test(user.password) ||!user.password) {
          
          errors.push("password")
       
        }

        if(!/^[a-zA-Z0-9]+$/
.test(user.empId) || !user.empId){
          errors.push("empId")
          
        }
        if(errors.length){
          user.error = "Invalid " + errors.join(', ') + ".";
          throw new Error(user.error)
        }
        user.license = [
          {
            "_id": "651588f225e52f2fdc7ea58b",
            "moduleName": "assist",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4997c04b7891731c3401a07d79",
                "subModuleName": "jd_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "380a82ac81e846acadf5e4c23953d9ce",
                "subModuleName": "recruiter_social",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "8b9f6ea51256437588c469cf587524c1",
                "subModuleName": "search_query_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "bebdd9ba91144359ac40eab5ec1",
                "subModuleName": "recruiter_outreach",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "9700dec940b249b295911e3dd134e172",
                "subModuleName": "domain_identifier",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "90561bdec9f94e87a3eb28e21a56554c",
                "subModuleName": "recommended_jobs",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "5464b5460af448cbaced04a55e4a9545",
                "subModuleName": "relevant_candidate_finder",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d7016",
                "subModuleName": "skill_highlighter",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d8670",
                "subModuleName": "interview_q_and_a",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              }
            ]
          },
          {
            "_id": "649588f225e52f2fdc7ea58c",
            "moduleName": "memories",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4197c04b7891731c3401a07d79",
                "subModuleName": "photo_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "_id": "649588f225e52fg4fdc7ea58b",
            "moduleName": "buzz",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "649288f235e52f2ddc7ea58c",
                "subModuleName": "buzz_admin",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "isAccessible": true,
            "moduleName": "home",
            "permissions": [
              {
                "_id": "75683b4c04b7891731c3401a07a99",
                "subModuleName": "notice_board",
                "create": false,
                "read": true,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07b89",
                "create": false,
                "read": true,
                "update": false,
                "delete": false,
                "subModuleName": "bulletin"
              },
              {
                "create": false,
                "delete": false,
                "read": true,
                "subModuleName": "notifications",
                "update": false,
                "_id": "75683b4c04b7891731c3401a07c69"
              }
            ]
          },
          {
            "_id": "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
            "moduleName": "admin",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "47697bb0-6127-4d88-835c-5bed120c9b91",
                "moduleName": "user_registration",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "649588f235e52f2fdc7ea58c",
                    "subModuleName": "single_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fdc7ea58d",
                    "subModuleName": "multi_users",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fma7ea58d",
                    "subModuleName": "manage_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {   
                "_id": "18533f25-94b2-4bbb-b966-32e7ee59a29c",
                "moduleName": "emailers",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                    "subModuleName": "bulk_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                    "subModuleName": "previous_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
               
                ]
              },
              {
                "_id": "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
                "moduleName": "template_bank",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "34d33fdc-435a-4150-821a-907e665cb6f7",
                    "subModuleName": "social_media_templates",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                    "subModuleName": "anniversary_template_bank",
                    "create": false,
                "read": false,
                "update": false,
                "delete": false
                  },
                  {
                    "_id": "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                    "subModuleName": "birthday_template_bank",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "75683b4197c04b7891731c3401a07d79",
                    "subModuleName": "image",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {
                "_id": "8a341493-7229-44b9-ab86-24a35a1a9c7d",
                "subModuleName": "jd_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "247ca95b-9a17-4047-8729-66c846de9097",
                "subModuleName": "boolean_quiz",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "abed0c70-5214-4eea-a7c8-f301e7f70c98",
                "subModuleName": "quiz_result",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "d456826a-b317-423b-b568-9c8417fd5a9a",
                "subModuleName": "quiz_evaluation",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07d79",
                "subModuleName": "role_based_access_control",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea5we8b",
                "subModuleName": "clan_score_master",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
                "subModuleName": "birthday_post",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea58b",
                "subModuleName": "activity_log",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },  
              {
                "_id": "90e8cb2c-eb60-4ae9-dx12-644ac923a8c1",
                "moduleName": "emailers",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "c11439b9-89cd-4999-bd5a-4ec71ea251f3",
                    "subModuleName": "bulk_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "391057ed-aff2-46cf-a02e-4fadbc893b2f",
                    "subModuleName": "previous_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {
                "_id": "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
                "subModuleName": "feedback",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          }
        ]
        delete user.isUploaded
        delete user.error
       
        
        const result = await userRegistrationService.createSingleUser(user);
        
            user.dateOfJoining = `${dojParts[0]}-${dojParts[1]}-${dojParts[2]}`
            user.dateOfBirth = `${dobParts[0]}-${dobParts[1]}-${dobParts[2]}`
            
        user.isUploaded = true;
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        setUploadedSuccessCounter((prev) => {
          return prev + 1;
        });
        console.log(result);

        setSuccess("User Created Successfully!");
        setError("");
      } catch (error) {
        
        user.dateOfJoining = `${dojParts[0]}-${dojParts[1]}-${dojParts[2]}`
        user.dateOfBirth = `${dobParts[0]}-${dobParts[1]}-${dobParts[2]}`
        if(error?.response?.data.message == "Email already taken"){
          
          user.error = error.response.data.message
        } else if(!errors.length) {
          user.error = "Server error"
        }
        setUserUploadCounter((prev) => {
          return prev + 1;
        });
        console.log("Error creating user:", error);
        setUploadedFailedCounter((prev) => {
          return prev + 1;
        });
        user.isUploaded = false;
        setError("Error Creating User!");
        setSuccess("");
        continue;
      }
    }
    // setShowPopup(!showPopup);
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }, [2000]);
    const fileInput = document.getElementById("customFile");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleUploadReportDownload = () => {
    const tempUsers = users;
    tempUsers.map((item) => (item.license = "object"));
    const jsonUsers = JSON.stringify(tempUsers);
    console.log(tempUsers);
    const csv = Papa.unparse(jsonUsers);
    const blob = new Blob([csv], { type: "text/csv" });

    const url = URL.createObjectURL(blob);

    const link = document.createElement("a");
    link.href = url;
    link.download = "usersUploadStatusReport.csv";

    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  useEffect(() => {
    let user = localStorage.getItem("user");
    let validator = new licenceValidator(user, "user_registration");
    setIsVisible(validator.isReadable("multi_users"));
  }, []);

  const handleEditSubmit = () => {
    if((/^[a-zA-Z\-/ ]+$/.test(firstNameRef.current.value) &&  /^[a-zA-Z\-/ ]+$/.test(lastNameRef.current.value))){
    try {
      const updatedUser = {
        empId: empIdRef.current.value, // Make sure to include the ID of the user
        firstName: firstNameRef.current.value,
        lastName: lastNameRef.current.value,
        department: departmentRef.current.value,
        role: usergroupRef.current.value,
        shift: shiftRef.current.value,
        email: emailRef.current.value,
        password: selectedUser.password,
        shiftTiming: shiftTimingsRef.current.value,
        license: [
          {
            "_id": "651588f225e52f2fdc7ea58b",
            "moduleName": "assist",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4997c04b7891731c3401a07d79",
                "subModuleName": "jd_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "380a82ac81e846acadf5e4c23953d9ce",
                "subModuleName": "recruiter_social",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "8b9f6ea51256437588c469cf587524c1",
                "subModuleName": "search_query_generator",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "bebdd9ba91144359ac40eab5ec1",
                "subModuleName": "recruiter_outreach",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "9700dec940b249b295911e3dd134e172",
                "subModuleName": "domain_identifier",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "90561bdec9f94e87a3eb28e21a56554c",
                "subModuleName": "recommended_jobs",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "5464b5460af448cbaced04a55e4a9545",
                "subModuleName": "relevant_candidate_finder",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d7016",
                "subModuleName": "skill_highlighter",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              },
              {
                "_id": "daaa953aa304466c84d6f642943d8670",
                "subModuleName": "interview_q_and_a",
                "create": true,
                "read": true,
                "update": true,
                "delete": true
              }
            ]
          },
          {
            "_id": "649588f225e52f2fdc7ea58c",
            "moduleName": "memories",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "75683b4197c04b7891731c3401a07d79",
                "subModuleName": "photo_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "_id": "649588f225e52fg4fdc7ea58b",
            "moduleName": "buzz",
            "isAccessible": true,
            "permissions": [
              {
                "_id": "649288f235e52f2ddc7ea58c",
                "subModuleName": "buzz_admin",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          },
          {
            "isAccessible": true,
            "moduleName": "home",
            "permissions": [
              {
                "_id": "75683b4c04b7891731c3401a07a99",
                "subModuleName": "notice_board",
                "create": false,
                "read": true,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07b89",
                "create": false,
                "read": true,
                "update": false,
                "delete": false,
                "subModuleName": "bulletin"
              },
              {
                "create": false,
                "delete": false,
                "read": true,
                "subModuleName": "notifications",
                "update": false,
                "_id": "75683b4c04b7891731c3401a07c69"
              }
            ]
          },
          {
            "_id": "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
            "moduleName": "admin",
            "isAccessible": false,
            "permissions": [
              {
                "_id": "47697bb0-6127-4d88-835c-5bed120c9b91",
                "moduleName": "user_registration",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "649588f235e52f2fdc7ea58c",
                    "subModuleName": "single_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fdc7ea58d",
                    "subModuleName": "multi_users",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "649588f235e52f2fma7ea58d",
                    "subModuleName": "manage_user",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {   
                "_id": "18533f25-94b2-4bbb-b966-32e7ee59a29c",
                "moduleName": "emailers",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                    "subModuleName": "bulk_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                    "subModuleName": "previous_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
               
                ]
              },
              {
                "_id": "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
                "moduleName": "template_bank",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "34d33fdc-435a-4150-821a-907e665cb6f7",
                    "subModuleName": "social_media_templates",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                    "subModuleName": "anniversary_template_bank",
                    "create": false,
                "read": false,
                "update": false,
                "delete": false
                  },
                  {
                    "_id": "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                    "subModuleName": "birthday_template_bank",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "75683b4197c04b7891731c3401a07d79",
                    "subModuleName": "image",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {
                "_id": "8a341493-7229-44b9-ab86-24a35a1a9c7d",
                "subModuleName": "jd_upload",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "247ca95b-9a17-4047-8729-66c846de9097",
                "subModuleName": "boolean_quiz",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "abed0c70-5214-4eea-a7c8-f301e7f70c98",
                "subModuleName": "quiz_result",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "d456826a-b317-423b-b568-9c8417fd5a9a",
                "subModuleName": "quiz_evaluation",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "75683b4c04b7891731c3401a07d79",
                "subModuleName": "role_based_access_control",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea5we8b",
                "subModuleName": "clan_score_master",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
                "subModuleName": "birthday_post",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },
              {
                "_id": "649588f225e52f1fdc7ea58b",
                "subModuleName": "activity_log",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              },  
              {
                "_id": "90e8cb2c-eb60-4ae9-dx12-644ac923a8c1",
                "moduleName": "emailers",
                "isAccessible": false,
                "permissions": [
                  {
                    "_id": "c11439b9-89cd-4999-bd5a-4ec71ea251f3",
                    "subModuleName": "bulk_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  },
                  {
                    "_id": "391057ed-aff2-46cf-a02e-4fadbc893b2f",
                    "subModuleName": "previous_mail",
                    "create": false,
                    "read": false,
                    "update": false,
                    "delete": false
                  }
                ]
              },
              {
                "_id": "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
                "subModuleName": "feedback",
                "create": false,
                "read": false,
                "update": false,
                "delete": false
              }
            ]
          }
        ],
        dateOfBirth:dateOfBirth,
        dateOfJoining:dateOfJoining
      };
      // Find the index of the user in the array
      const userIndex = users.findIndex(
        (user) => user.email === selectedUser.email
      );

      // Create a new array with the updated user object
      const updatedUsers = [...users];
      updatedUsers[userIndex] = updatedUser;
      setUsers(updatedUsers);
      if(/^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()-_+=\[\]{}|;:'",.<>?\\/]{8,}$/.test(password)){
        const updatedUser = {
          empId: empIdRef.current.value, 
          firstName: firstNameRef.current.value,
          lastName: lastNameRef.current.value,
          department: departmentRef.current.value,
          role: usergroupRef.current.value,
          shift: shiftRef.current.value,
          email: emailRef.current.value,
          password: password,
          shiftTiming: shiftTimingsRef.current.value,
          license: [
            {
              "_id": "651588f225e52f2fdc7ea58b",
              "moduleName": "assist",
              "isAccessible": true,
              "permissions": [
                {
                  "_id": "75683b4997c04b7891731c3401a07d79",
                  "subModuleName": "jd_generator",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "380a82ac81e846acadf5e4c23953d9ce",
                  "subModuleName": "recruiter_social",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "8b9f6ea51256437588c469cf587524c1",
                  "subModuleName": "search_query_generator",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "bebdd9ba91144359ac40eab5ec1",
                  "subModuleName": "recruiter_outreach",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "9700dec940b249b295911e3dd134e172",
                  "subModuleName": "domain_identifier",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "90561bdec9f94e87a3eb28e21a56554c",
                  "subModuleName": "recommended_jobs",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "5464b5460af448cbaced04a55e4a9545",
                  "subModuleName": "relevant_candidate_finder",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "daaa953aa304466c84d6f642943d7016",
                  "subModuleName": "skill_highlighter",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                },
                {
                  "_id": "daaa953aa304466c84d6f642943d8670",
                  "subModuleName": "interview_q_and_a",
                  "create": true,
                  "read": true,
                  "update": true,
                  "delete": true
                }
              ]
            },
            {
              "_id": "649588f225e52f2fdc7ea58c",
              "moduleName": "memories",
              "isAccessible": true,
              "permissions": [
                {
                  "_id": "75683b4197c04b7891731c3401a07d79",
                  "subModuleName": "photo_upload",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                }
              ]
            },
            {
              "_id": "649588f225e52fg4fdc7ea58b",
              "moduleName": "buzz",
              "isAccessible": true,
              "permissions": [
                {
                  "_id": "649288f235e52f2ddc7ea58c",
                  "subModuleName": "buzz_admin",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                }
              ]
            },
            {
              "isAccessible": true,
              "moduleName": "home",
              "permissions": [
                {
                  "_id": "75683b4c04b7891731c3401a07a99",
                  "subModuleName": "notice_board",
                  "create": false,
                  "read": true,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "75683b4c04b7891731c3401a07b89",
                  "create": false,
                  "read": true,
                  "update": false,
                  "delete": false,
                  "subModuleName": "bulletin"
                },
                {
                  "create": false,
                  "delete": false,
                  "read": true,
                  "subModuleName": "notifications",
                  "update": false,
                  "_id": "75683b4c04b7891731c3401a07c69"
                }
              ]
            },
            {
              "_id": "ppd6df3c-po78-9030-kpo0-12fg35fyy56b",
              "moduleName": "admin",
              "isAccessible": false,
              "permissions": [
                {
                  "_id": "47697bb0-6127-4d88-835c-5bed120c9b91",
                  "moduleName": "user_registration",
                  "isAccessible": false,
                  "permissions": [
                    {
                      "_id": "649588f235e52f2fdc7ea58c",
                      "subModuleName": "single_user",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "649588f235e52f2fdc7ea58d",
                      "subModuleName": "multi_users",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "649588f235e52f2fma7ea58d",
                      "subModuleName": "manage_user",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    }
                  ]
                },
                {   
                  "_id": "18533f25-94b2-4bbb-b966-32e7ee59a29c",
                  "moduleName": "emailers",
                  "isAccessible": false,
                  "permissions": [
                    {
                      "_id": "bae1e553-9f21-43b5-bb1e-e744e5f03c03",
                      "subModuleName": "bulk_mail",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "fda0b0ae-c480-4711-86a0-244a5b3e48a2",
                      "subModuleName": "previous_mail",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                  ]
                },
                {
                  "_id": "90e8cb2c-eb60-4ae9-bf1a-644ac923a8c1",
                  "moduleName": "template_bank",
                  "isAccessible": false,
                  "permissions": [
                    {
                      "_id": "34d33fdc-435a-4150-821a-907e665cb6f7",
                      "subModuleName": "social_media_templates",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "9d433b61-ff41-45b8-8c25-593a99f2a40c",
                      "subModuleName": "anniversary_template_bank",
                      "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                    },
                    {
                      "_id": "e0f7034a-cff1-4b9b-8002-fbc2b26dbbd7",
                      "subModuleName": "birthday_template_bank",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "75683b4197c04b7891731c3401a07d79",
                      "subModuleName": "image",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    }
                  ]
                },
                {
                  "_id": "8a341493-7229-44b9-ab86-24a35a1a9c7d",
                  "subModuleName": "jd_upload",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "247ca95b-9a17-4047-8729-66c846de9097",
                  "subModuleName": "boolean_quiz",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "abed0c70-5214-4eea-a7c8-f301e7f70c98",
                  "subModuleName": "quiz_result",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "d456826a-b317-423b-b568-9c8417fd5a9a",
                  "subModuleName": "quiz_evaluation",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "75683b4c04b7891731c3401a07d79",
                  "subModuleName": "role_based_access_control",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "649588f225e52f1fdc7ea5we8b",
                  "subModuleName": "clan_score_master",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "c6fac7d3-bcf3-4da9-828c-2e0045bacd5c",
                  "subModuleName": "birthday_post",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },
                {
                  "_id": "649588f225e52f1fdc7ea58b",
                  "subModuleName": "activity_log",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                },  
                {
                  "_id": "90e8cb2c-eb60-4ae9-dx12-644ac923a8c1",
                  "moduleName": "emailers",
                  "isAccessible": false,
                  "permissions": [
                    {
                      "_id": "c11439b9-89cd-4999-bd5a-4ec71ea251f3",
                      "subModuleName": "bulk_mail",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    },
                    {
                      "_id": "391057ed-aff2-46cf-a02e-4fadbc893b2f",
                      "subModuleName": "previous_mail",
                      "create": false,
                      "read": false,
                      "update": false,
                      "delete": false
                    }
                  ]
                },
                {
                  "_id": "e93ed89e-ad89-41f3-b2aa-b22ebaece238",
                  "subModuleName": "feedback",
                  "create": false,
                  "read": false,
                  "update": false,
                  "delete": false
                }
              ]
            }
          ],
          dateOfBirth:dateOfBirth,
          dateOfJoining:dateOfJoining
        };
        // Find the index of the user in the array
        const userIndex = users.findIndex(
          (user) => user.email === selectedUser.email
        );
  
        // Create a new array with the updated user object
        const updatedUsers = [...users];
        updatedUsers[userIndex] = updatedUser;
        setUsers(updatedUsers);
        setSuccess("Edited Successfully!");
        setError("");
      } else {
        setShowAlert(true);
        setError("Password must contain aleast 8 characters , 1 letter and 1 number");
        setSuccess("");
        setTimeout(() => {
          setShowAlert(false);
          setError("");
          setSuccess("");
        }, [3000]);
        return
      }
      setSuccess("Edited Successfully!");
      setError("");
    setShowEditPopup(false);

      
    } catch (err) {
      setSuccess("");
 

      setError("Editing failed!");
    }
  }
  else {
    if(!/^[a-zA-Z\-/ ]+$/.test(firstNameRef.current.value)){
      setShowAlert(true);
      setError("First Name should be alphabets")
      console.log("Error")
    setTimeout(() => {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }, [2000]);
    }
    if(!/^[a-zA-Z\-/ ]+$/.test(lastNameRef.current.value)){
      setShowAlert(true);
      setError("Last Name should be alphabets")
    setTimeout(() => {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }, [2000]);
    }

  }
    setShowAlert(true);
    setTimeout(() => {
      setShowAlert(false);
      setError("");
      setSuccess("");
    }, [2000]);

    setShowPopup(true);
  };

  function handleUpdate(item) {
    setSelectedUser(item);
    setSelectedDepartment(item.department)
    
    const selectedDepartmentData = departments.find(department => department.name === item.department);
    
    if (selectedDepartmentData) {
        const selectedShiftData = selectedDepartmentData?.shifts.find(shift => shift.name === item.shift);
    
        if (selectedShiftData) {
            setShiftTimings(selectedShiftData?.shiftTimings || []);
            const selectedShiftTimingData = selectedShiftData.shiftTimings.find(timing => timing === item.shiftTiming);
            if(!selectedShiftTimingData){
              setShowAlert(true)
              setError("Invalid Shift Timing");
            }
            setShiftTime(item.shiftTiming); 
        } else {
          setShowAlert(true)
          setError("Invalid Shift");
        }
    } else {
      setShowAlert(true)
      setError("Invalid Department");
    }
    
    setPassword(item.password)
    setShowEditPopup(!showEditPopup);
    // setShowPopup(!showPopup);
  }
  function handleDelete(item) {
    let newUsers = users.filter((ele) => ele.email !== item.email);
    if(!newUsers.length){
      setShowPopup(!showPopup)
    }
    setUsers(newUsers);
    setShowAlert(false);
    setTimeout(() => {
      setShowAlert(true);
      setError("");
      setSuccess("Item Deleted Succesfully");
    }, [2000]);
  }
  
  const handleSubmit = (event) => {
    let file = document.getElementById("customFile").files[0];

    if (file) {
      const fileName = file.name;
      const extension = fileName.split(".").pop().toLowerCase();
      let hasError = false;
      let missingFields = [];

      let targetRow = {
        department: "",
        shift: "",
        shiftTiming: "",
        empId: "",
        email: "",
        password:"",
        firstName: "",
        lastName: "",
        dateOfBirth:"",
        dateOfJoining:""
      };

      if (extension === "xlsx" || extension === "xls") {
        const reader = new FileReader();
        reader.onload = (e) => {
          const data = e.target.result;
          const workbook = xlsx.read(data, { type: "array" });
          const sheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[sheetName];
          const jsonData = xlsx.utils.sheet_to_json(worksheet);
          const excelDateToJSDate = (serial) => {
            // Excel serial date to JavaScript Date conversion
            const utc_days = Math.floor(serial - 25569); // Excel epoch starts at 25569
            const date_info = utc_days * 86400; // Seconds in a day
            const date = new Date(date_info * 1000); // JS Date expects milliseconds
        
            // Return formatted date as M/D/YYYY without leading zeros
            const month = date.getUTCMonth() + 1; // getMonth() is zero-based
            const day = date.getUTCDate();
            const year = date.getUTCFullYear();
            const formattedDay = day < 10 ? `0${day}` : day;
            const formattedMonth = month < 10 ? `0${month}` : month;
          
            return `${formattedDay}-${formattedMonth}-${year}`;
          };
          const json = jsonData.map((row) => {
            if (row.dateOfBirth) {
              
              row.dateOfBirth = excelDateToJSDate(row.dateOfBirth);  // Format to MM/DD/YYYY
            }
            if (row.dateOfJoining) {
              
              row.dateOfJoining = excelDateToJSDate(row.dateOfJoining);  // Format to MM/DD/YYYY
            }
            
            if (row.empId) {
              row.empId = row.empId.toString();
            }
            return row;
          });
          setUsers(json); 
          
          setShowPopup(!showPopup);

          missingFields = Object.keys(targetRow).filter((field) => {
            return jsonData.every((row) => row[field] === undefined);
          });

          if (missingFields.length === 0) {
            ;
            console.log("All fields in the targetRow exist in the data.");
          } else {
            hasError = false;
            // console.error("There are missing row here", missingFields)
            setError(`This Field is missing: ${missingFields.join(", ")}`);
            setShowAlert(true);
            setTimeout(() => {
              setShowAlert(false);
              setError("");
              setSuccess("");
            }, 2000);

            console.error(
              `${missingFields.join(", ")} ${
                missingFields.length > 1 ? "are" : "is"
              } missing in the data.`
            );
          }
        };
        reader.readAsArrayBuffer(file);
      } else if (extension === "csv") {
        Papa.parse(file, {
          header: true,
          skipEmptyLines: true,
          complete: function (results) {
            setUsers(results.data);
            setShowPopup(!showPopup);

            // Check which fields are missing in the targetRow
            missingFields = Object.keys(targetRow).filter((field) => {
              return !results.data.some((row) => row[field] !== undefined);
            });

            if (missingFields.length === 0) {
              console.log("All fields in the targetRow exist in the data.");
            } else {
              ;
              hasError = false;
              setError(`This Field is missing: ${missingFields.join(", ")}`);
              // console.error("There are missing row here", missingFields)
              setShowAlert(true);
              setTimeout(() => {
                setShowAlert(false);
                setError("");
                setSuccess("");
              }, 2000);
              return;

              // console.error(`${missingFields.join(", ")} ${missingFields.length > 1 ? "are" : "is"} missing in the data.`);
            }
          },
        });
      } else {
        setError("Invalid file type. Please upload a CSV or XLSX file.!");
        setShowAlert(true);
        setTimeout(() => {
          setShowAlert(false);
          setError("");
          setSuccess("");
        }, 2000);
      }

      // Check for errors before submitting
      if (!hasError) {
        // Submit the form or take other appropriate action
      }
    } else {
      setError("No files selected!");
      setShowAlert(true);
      setTimeout(() => {
        setShowAlert(false);
        setError("");
        setSuccess("");
      }, 2000);
    }
  };

  const autoGeneratePassword = () => {
    const length = 10;
const letterCharset = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
const numberCharset = "0123456789";
const specialCharset = "!@#$%^&*()";
const charset = letterCharset + numberCharset + specialCharset;

// Generate at least one letter and one number
let randomPassword = "";

// Add a random letter
const randomLetterIndex = Math.floor(Math.random() * letterCharset.length);
randomPassword += letterCharset[randomLetterIndex];

// Add a random number
const randomNumberIndex = Math.floor(Math.random() * numberCharset.length);
randomPassword += numberCharset[randomNumberIndex];

// Generate the remaining characters
for (let i = 2; i < length; i++) {
  const randomIndex = Math.floor(Math.random() * charset.length);
  randomPassword += charset[randomIndex];
}

    setPassword(randomPassword);
    let user = selectedUser;
    user.password = randomPassword;
    setSelectedUser(user);
  };

const handleDOBChange = (e) => {
  const selectedDate = new Date(e); // Convert the event's value to a Date object

  const day = String(selectedDate.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
  const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad with zero
  const year = selectedDate.getFullYear(); // Get full year

  const formattedDate = `${day}/${month}/${year}`;
  
  setDateOfBirth(formattedDate);
  
  setSelectedUser({
    ...selectedUser,
    dateOfBirth: formattedDate,
  });
};
  const handleDOJChange = async (e)=>{
    const selectedDate = new Date(e); // Convert the event's value to a Date object

    const day = String(selectedDate.getDate()).padStart(2, '0'); // Get day and pad with zero if needed
    const month = String(selectedDate.getMonth() + 1).padStart(2, '0'); // Get month (0-indexed) and pad with zero
    const year = selectedDate.getFullYear(); // Get full year
  
    const formattedDate = `${day}/${month}/${year}`;
    
    // setDateOfBirth(formattedDate);
  setDateOfJoining(formattedDate)
    setSelectedUser({
      ...selectedUser,
      dateOfJoining: formattedDate,
    });
  }

  function convertDateFormat(dateString) {
    if (dateString) {
      const [day, month, year] = dateString.split(/[-/]/);
      return new Date(`${year}-${month}-${day}`);
  }
  return null;
}


  return (
    <>
      {showPopup ? (
        <div className="user-reg-popup-container">
          <div className="user-reg-popup w-75">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>
              {showCounter ? (
                <div className="upload-counter my-2 fw-bold">
                  <span className="mx-2">
                    Total Records: {userUploadCounter} / {users.length}
                  </span>
                  <span className="text-success mx-2">
                    Success: {uploadedSuccessCounter}
                  </span>
                  <span className="text-danger mx-2">
                    Failed: {uploadedFailedCounter}
                  </span>
                  <span
                    className="ms-4"
                    role="button"
                    data-toggle="tooltip"
                    title="download"
                    onClick={handleUploadReportDownload}
                  >
                    <BiDownload
                      style={{
                        width: "22px",
                        height: "22px",
                        paddingBottom: "3px",
                      }}
                    />
                  </span>
                </div>
              ) : null}

              <button
                className="close-button"
                onClick={() => {
                  setShowPopup(!showPopup);
                  setUserUploadCounter(0);
                  setUploadedFailedCounter(0);
                  setUploadedSuccessCounter(0);
                  setShowCounter(false);
                }}
              >
                &#10005;
              </button>
            </div>

            <hr style={{ borderTop: "2px dashed black" }} />
            <div className="table-responsive" style={{ maxHeight: "400px" }}>
              <table className="table">
                <thead>
                  <tr>
                    <th scope="col" style={{ color: "#175572" }}>
                      Email
                    </th>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Department</th>
                    <th scope="col">Shift timing</th>
                    <th scope="col">Date of Birth</th>
                    <th scope="col">Date of Joining</th>
                    <th scope="col"></th>
                    <th scope="col"></th>
                    <th scope="col">Upload Status</th>
                
                    <th scope="col"style={{ textAlign: "center" }}>Error</th>

                  </tr>
                </thead>

                <tbody>
                  {users.length > 0
                    ? users.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ color: "#175572" }}>{item.email}</td>
                            <td>{item.firstName}</td>
                            <td>{item.lastName}</td>
                            <td>{item.department}</td>
                            <td>{item.shiftTiming}</td>
                            <td>{item.dateOfBirth}</td>
                            <td>{item.dateOfJoining}</td>
                            <td onClick={() => handleUpdate(item)}>
                              <FaEdit style={{ color: "#175572" }} />
                            </td>
                            {/* <td
                        onClick={() => {
                          setSelectedDetails(item);
                          setShowDetails(!showDetails);
                        }}
                      >
                        <FaUserAlt style={{ color: "#175572" }} />
                      </td> */}
                            <td
                              onClick={() => {
                                const confirmBox = window.confirm(
                                  "Are you sure you want to delete this item?"
                                );
                                ;
                                if (confirmBox === true) {
                                  console.log("Entry Deleted!!!!");

                                  handleDelete(item);
                                } else {
                                  console.log("Entry Not Deleted");
                                }
                              }}
                            >
                              <FaTrashAlt style={{ fill: "red" }} />
                            </td>

                            <td style={{ textAlign: "center" }}>
                              {item.isUploaded === false ? (
                                <AiFillCloseCircle
                                  style={{
                                    fill: "red",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                  data-toggle="tooltip"
                                  title="upload failed"
                                />
                              ) : item.isUploaded === undefined ? (
                                "-"
                              ) : (
                                <AiFillCheckCircle
                                  data-toggle="tooltip"
                                  title="upload success"
                                  style={{
                                    fill: "green",
                                    width: "20px",
                                    height: "20px",
                                  }}
                                />
                              )}
                            </td>
                         {item.error ?   <td style={{ textAlign: "center" }}>
                                    {item.error}
                            </td> :<></>}
                          </tr>
                        );
                      })
                    : null}
                </tbody>
              </table>
            </div>
            <div className="d-flex justify-content-center pt-2">
              <button
                className="generate-btn"
                onClick={handleUserRegistration}
                type="submit"
              >
                Confirm
              </button>
              {/* <button onClick={() => setShowPopup(false)}>Edit</button> */}
            </div>
            {/* <div className="pagination my-2">
        <button
          onClick={handlePrevPage}
          disabled={currentPage === 1}
          className={currentPage === 1 ? "active" : ""}
        >
          <FaLessThan className="arrow" />
        </button>
        {pageNumbers.map((page) => (
          <button
            key={page}
            disabled={currentPage === page}
            className={currentPage === page ? "active" : ""}
            onClick={() => {
              paginate(page);
              adjustPages(page);
            }}
          >
            {page}
          </button>
        ))}
        <button
          onClick={handleNextPage}
          disabled={currentPage === totalPages}
          className={currentPage === totalPages ? "active" : ""}
        >
          <FaGreaterThan className="arrow" />
        </button>
      </div> */}
          </div>
        </div>
      ) : null}

      {/* Edit Popup */}
      {showEditPopup && selectedUser ? (
        <div className="user-reg-popup-container">
          <div className="user-reg-popup ">
            <div className="user-reg-popup-header">
              <span className="fs-6 fw-bold">Details</span>
              <button
                className="close-button"
                onClick={() => {    
                  setShowEditPopup(!showEditPopup);
                }}
              >
                &#10005;
              </button>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="firstName">
                    First Name
                  </label>
                  <input
                    type="text"
                    ref={firstNameRef}
                    defaultValue={selectedUser.firstName}
                    id="firstName"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="lastName">
                    Last Name
                  </label>
                  <input
                    type="text"
                    ref={lastNameRef}
                    defaultValue={selectedUser.lastName}
                    id="lastName"
                    className="form-control"
                    required
                  />
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="empId">
                    Employee ID
                  </label>
                  <input
                    type="text"
                    ref={empIdRef}
                    defaultValue={selectedUser.empId}
                    id="empId"
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="department">
                    Department
                  </label>
                  <select id="department" className="form-select" ref={departmentRef} onChange={handleDepartmentChange} defaultValue={selectedUser.department}>
                    <option value="default">Select Department</option>
                    {departments.map((department, index) => (
                      <option key={index} value={department.name} >
                        {department.name}
                      </option>
                    ))}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="email">
                    User Group
                  </label>
                  <select
                    id="user-group"
                    className="form-select"
                    ref={usergroupRef}
                    defaultValue={selectedUser.role}
                  >
                    <option value="Administration" disabled className="p-5">
                      Select an option
                    </option>
                    <option value="user">User</option>
                    {/* We do not need this option as of now. */}
                    {/* <option value="admin">Admin</option>  */}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="region">
                    Shift
                  </label>
                  <select id="shift" className="form-select" ref={shiftRef} onChange={handleShiftChange} defaultValue={selectedUser.shift} required>
                    <option value="default">Select Shift</option>
                    {departments
                      .find(department => department.name === selectedDepartment)
                      ?.shifts.map((shift, index) => (
                        <option key={index} value={shift.name}>
                          {shift.name}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="email">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    ref={emailRef}
                    defaultValue={selectedUser.email}
                    className="form-control"
                    required
                  />
                </div>
              </div>
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="shift">
                    Shift timing
                  </label>
                  <select id="shiftTimings" className="form-select" ref={shiftTimingsRef} onChange={handleShiftTimingChange} defaultValue={selectedUser.shiftTiming} required>
                    <option value="default">Select Shift Timings</option>
                    {shiftTimings.map((timing, index) => (
                      <option key={index} value={timing}>
                        {timing}
                      </option>
                    ))}
                  </select>
                  <div className="arrow-down"></div>
                </div>
              </div>
            </div>
            <div className="row ">
              <div className="col">
                <div className="form-outline">
                  <label className="form-label" htmlFor="password">
                    Password
                  </label>
                  <div className="input-group mb-3">
                    <input
                      type={showPassword ? "text" : "password"}
                      className="form-control"
                      placeholder="Password"
                      aria-label="Password"
                      aria-describedby="button-addon2"
                      autoComplete="off"
                      value={password}
                      defaultValue={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <button
                      className="btn"
                      type="button"
                      id="button-addon2"
                      value={password}
                      onClick={() => setShowPassword(!showPassword)}
                    >
                      {showPassword ? <BsEyeSlash /> : <BsEye />}
                    </button>
                    <button
                      className="btn btn-outline-secondary"
                      type="button"
                      id="generate-btn"
                      onClick={autoGeneratePassword}
                    >
                      Generate
                    </button>
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="form-outline"> 
                  <label className="form-label" htmlFor="DOB">
                    Date of birth
                  </label>
                  <div className="input-group mb-3">
                        <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dobRef}
                      selected={convertDateFormat(selectedUser.dateOfBirth)}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e)=> {handleDOBChange(e)}}
                      placeholderText="Date Of Birth"
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <div className="form-outline"> 
                    <label className="form-label" htmlFor="DOJ">
                      Date of Joining
                    </label>
                    <div className="input-group mb-3">
                    <DatePicker
                      type="date"
                      className="form-control w-100"
                      id="dateOfBirthInput"
                      ref={dobRef}
                      selected={convertDateFormat(selectedUser.dateOfJoining)}
                      dateFormat="dd/MM/yyyy"
                      onChange={(e)=> {handleDOJChange(e)}}
                      placeholderText="Date Of Joining"
                    />
                    </div>
                </div>
              </div>
              <div className="col"></div>
            </div>
            <center>
              {/* submit functionality */}
              <button className="generate-btn " onClick={handleEditSubmit}>
                Submit
              </button>
            </center>
          </div>
        </div>
      ) : null}

      <form className="multi-user-form mt-3 d-flex flex-column mx-2">
        <div className="form-outline">
          <label className="form-label" htmlFor="customFile">
            Upload excel/csv file
          </label>
          <input
            type="file"
            className="form-control"
            id="customFile"
            accept=".xlsx, .csv"
            required
          />
        </div>
        <div className="d-flex justify-content-between">
          <a
            href={Datasheet}
            download
            className="text-primary mt-4"
            style={{ textDecoration: "underline" }}
          >
            <h6>Sample User Data Sheet</h6>{" "}
          </a>
          {isVisible ? (
            <button
              className="generate-btn mt-3 "
              onClick={handleSubmit}
              type="button"
            >
              Add
            </button>
          ) : null}
        </div>
      </form>

      {showAlert ? (
        <div className="alert-container">
          <div className="upper-section">
            <p>ALOIS</p>
            <span  className="delete-btn" onClick={() => setShowAlert(!showAlert)}>
              <AiOutlineClose className="icon" />
            </span>
          </div>
          <hr />
          <div className="lower-section pt-2">
            <p className="text-danger">{error}</p>
            <p className="text-success">{success}</p>
          </div>
        </div>
      ) : null}
    </>
  );
}

export default MultiUser;
